import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import { Trash2 } from 'lucide-react'
import { toast } from 'sonner'
import useDeleteStoryPage from '@/api/pages/mutatations/useDeleteStoryPage'
import { type Page } from '@/api/pages/types'
import { Button } from '@/components/shadcn/Button'
import { useConfirmActionDialog } from '@/stores/useConfirmDialog'
import { CONFIRM_TEXT, TOAST_TEXT } from '@/constants/ConfirmText'
import PreviewButton from './PreviewButton'
import PublishButton from './PublishButton'

interface PageCardActionsProps {
  page: Page
}

const StoryPageActions: React.FC<PageCardActionsProps> = ({ page }) => {
  const client = useQueryClient()
  const isMutating = useIsMutating() > 0

  const { openDialog } = useConfirmActionDialog()

  const { mutate: mutateDelete } = useDeleteStoryPage({})

  const deletePage = () => {
    mutateDelete(
      { pageId: page.id, storyId: page.idStory },
      {
        onSuccess: async () => {
          await client.invalidateQueries()
          toast.success(TOAST_TEXT.DELETE_PAGE.success)
        },
        onError: e => {
          toast.error(TOAST_TEXT.DELETE_PAGE.error)
          toast.error(e.response?.data?.message)
        },
      }
    )
  }

  return (
    <div className="flex flex-col transition-all duration-200">
      <PreviewButton page={page} />
      <PublishButton page={page} />
      <Button
        variant="ghost"
        disabled={isMutating}
        className="flex w-full items-center justify-start gap-2 font-bold text-red-700"
        onClick={e => {
          e.stopPropagation()
          openDialog({
            title: CONFIRM_TEXT.DELETE_PAGE.title,
            description: CONFIRM_TEXT.DELETE_PAGE.description,
            onCancel: () => {},
            onConfirm: deletePage,
          })
        }}
      >
        <Trash2 className="size-5" strokeWidth={2} />
        <p>Smazat</p>
      </Button>
    </div>
  )
}

export default StoryPageActions
