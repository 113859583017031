import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import { CornerLeftDown, Upload } from 'lucide-react'
import moment from 'moment'
import { toast } from 'sonner'
import { CONFIRM_TEXT, TOAST_TEXT } from '@/constants/ConfirmText'
import useSaveStoryPage from '@/api/pages/mutatations/useSaveStoryPage'
import { useConfirmActionDialog } from '@/stores/useConfirmDialog'
import getPublishedStatus from '@/utils/getPublishedStatus'
import { MediaJobStatus, Page } from '@/api/pages/types'
import { PublishStatus } from '@/constants/publishConst'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/Tooltip'
import { Button } from '../shadcn/Button'

type Props = {
  page: Page
}

const DISABLED_STATUSES = [MediaJobStatus.ERROR, MediaJobStatus.CANCELLED, MediaJobStatus.PROGRESSING, MediaJobStatus.SUBMITTED]

const PublishButton = ({ page }: Props) => {
  const status = getPublishedStatus(page)

  const isMutating = useIsMutating() > 0

  const client = useQueryClient()

  const { mutate: mutateStatus } = useSaveStoryPage()

  const { openDialog } = useConfirmActionDialog()

  const updatePage = (data: Page) => {
    mutateStatus(data, {
      onSuccess: async () => {
        await client.invalidateQueries()
        if (data.published) {
          toast.success(TOAST_TEXT.PUBLISH_PAGE.success)
        } else {
          toast.success(TOAST_TEXT.UNPUBLISH_PAGE.success)
        }
      },
      onError: e => {
        toast.error(TOAST_TEXT.PUBLISH_PAGE.error)
        toast.message(e.response?.data?.message)
      },
    })
  }

  if (status === PublishStatus.DRAFT)
    return (
      <Tooltip>
        <TooltipTrigger>
          <Button
            variant={DISABLED_STATUSES.includes(page.mediaJobStatus!) ? 'disabled' : 'ghost'}
            disabled={isMutating}
            className="flex w-full items-center justify-start gap-2 font-bold"
            onClick={e => {
              e.stopPropagation()
              openDialog({
                title: CONFIRM_TEXT.PUBLISH_PAGE.title,
                description: CONFIRM_TEXT.PUBLISH_PAGE.description,
                onCancel: () => {},
                onConfirm: () => {
                  updatePage({
                    ...page,
                    published: moment().toDate(),
                    ttl: moment().add(7, 'days').toDate(),
                  })
                },
              })
            }}
          >
            <Upload className="size-5" strokeWidth={2} />
            <p>Publikovat</p>
          </Button>
        </TooltipTrigger>
        <TooltipContent className="text-xs">Publikovat příběh není možné z důvodu zpracování videa.</TooltipContent>
      </Tooltip>
    )

  if (status === PublishStatus.PUBLISHED)
    return (
      <Button
        variant="ghost"
        disabled={isMutating}
        className="flex w-full items-center justify-start gap-2 font-bold"
        onClick={e => {
          e.stopPropagation()
          openDialog({
            title: CONFIRM_TEXT.UNPUBLISH_PAGE.title,
            description: CONFIRM_TEXT.UNPUBLISH_PAGE.description,
            onCancel: () => {},
            onConfirm: () => {
              updatePage({ ...page, published: null, ttl: null })
            },
          })
        }}
      >
        <CornerLeftDown className="size-5" strokeWidth={2} />
        <p>Zrušit publikování</p>
      </Button>
    )

  return null
}

export default PublishButton
