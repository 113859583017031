import { createBrowserRouter, Navigate } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import Page404 from '@/pages/Page404'
import Dashboard from '@/pages/dashboard'
import EditorialLayout from '@/components/Layout/EditorialLayout'
import Draft from '@/pages/editorial/draft'
import Archived from '@/pages/editorial/archived'
import Published from '@/pages/editorial/published'
import ProtectedRoute from '@/components/ProtectedRoute'
import Root from '@/pages/root'

import NewStoryPage from '@/pages/story-detail'
import SignIn from '@/pages/sign-in/sign-in'
import { CognitoGroup } from '@/types/users'
import ContextProvider from '@/providers/ContextProvider'
import RoutePaths from './routes'
import PrivateRoute from './PrivateRoute'
import RedirectRoute from './RedirectRoute'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router: ReturnType<typeof createBrowserRouter> = sentryCreateBrowserRouter([
  {
    element: <ContextProvider />,
    children: [
      {
        path: '/',
        element: <PrivateRoute />,
        children: [
          {
            path: RoutePaths.ROOT,
            element: <Root />,
          },
          {
            path: RoutePaths.DASHBOARD,
            element: (
              <ProtectedRoute cognitoGroup={CognitoGroup.ADMIN}>
                <Dashboard />
              </ProtectedRoute>
            ),
          },
          {
            path: RoutePaths.EDITORIAL,
            element: <EditorialLayout />,
            children: [
              {
                index: true,
                element: <Navigate to="published" replace />,
              },
              {
                path: RoutePaths.DRAFT,
                element: <Draft />,
                index: true,
              },
              {
                path: RoutePaths.PUBLISHED,
                element: <Published />,
              },
              {
                path: RoutePaths.ARCHIVE,
                element: <Archived />,
              },
              {
                path: RoutePaths.STORY_DETAIL,
                element: <NewStoryPage />,
              },
            ],
          },
        ],
      },
      {
        element: <RedirectRoute />,
        children: [{ path: RoutePaths.SIGN_IN, element: <SignIn /> }],
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
])
