import { Eye } from 'lucide-react'
import config from '@/config'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/shadcn/Tooltip'
import { MediaJobStatus, type Page } from '@/api/pages/types'
import { buttonVariants } from '@/components/shadcn/Button'

type Props = {
  page: Page
}

const DISABLED_STATUSES = [MediaJobStatus.ERROR, MediaJobStatus.CANCELLED, MediaJobStatus.PROGRESSING, MediaJobStatus.SUBMITTED]

const PreviewButton = ({ page }: Props) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <a
          href={`${config.api.storyBaseUrl}/story/page/preview/${page.id}`}
          target="_blank"
          className={buttonVariants({
            variant: DISABLED_STATUSES.includes(page.mediaJobStatus!) ? 'disabled' : 'ghost',
            className: 'flex w-full !justify-start gap-2 font-bold',
          })}
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          <Eye className="size-5" strokeWidth={2} />
          <p>Náhled</p>
        </a>
      </TooltipTrigger>
      {DISABLED_STATUSES.includes(page.mediaJobStatus!) && (
        <TooltipContent className="text-xs">Náhled není možné zobrazit z důvodu zpracování videa.</TooltipContent>
      )}
    </Tooltip>
  )
}

export default PreviewButton
