export enum MediaJobStatus {
  SUBMITTED = 'SUBMITTED',
  PROGRESSING = 'PROGRESSING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

export interface Page {
  id: string
  published?: Date | string | null
  ttl?: Date | string | null
  idStory: string
  imageS3Key: string
  coverS3Key: string | null
  text: string
  emoji: string
  label: string
  link: string
  updateImage?: boolean
  cropBBox?: {
    left: number
    top: number
    width: number
    height: number
  } | null
  idTemplate?: string | null
  previewTill?: Date | null
  dateCreated?: Date
  dateUpdated?: Date
  order: number
  generated: boolean
  mediaJobId: string | null
  mediaJobStatus: MediaJobStatus | null
}

export interface StoryPageTemplate {
  id: string
  name: string | null
  description: string | null
  coverS3Key: string | null
  text: string
  required: string[]
}

export interface AddStoryPageBody extends Page {}
export type SaveStoryPageBody = Page

export interface SetStoryPageStatusResponse extends Page {}
export interface DeleteStoryPageResponse extends Page {}
export interface AddStoryPageResponse extends Page {}
export interface GetStoryPageResponse extends Page {}
export interface SaveStoryPageResponse extends Page {}
export type GetStoryPageTemplatesResponse = StoryPageTemplate[]

export interface SetStoryPageStatusParams {
  storyId: string
  pageId: string
  status: string
}

export interface DeleteStoryPageParams {
  storyId: string
  pageId: string
}

export interface GetStoryPageParams {
  storyId: string
  pageId: string
}

export interface UploadPhotoResponse {
  photo: string
}

export interface UploadPhotoParams {
  pageId: string
}
