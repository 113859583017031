import { Ban } from 'lucide-react'

const VideoFailure = () => {
  return (
    <div className="flex h-80 w-44 max-w-44 shrink-0 flex-col items-center justify-center space-y-2 rounded-lg border border-zinc-200 bg-gray-100 text-slate-400">
      <Ban className="size-16" />
      <p className="text-center text-sm text-slate-400">Soubor se nepodařilo nahrát</p>
    </div>
  )
}

export default VideoFailure
